import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import {
  BpkButtonV2,
  BUTTON_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../skyscanner-application/i18n';
import { HASH_VIEWED_HOTELS } from '../../../services/routes';
import Section from '../../../screens/HotelDetailsPage/ExpandableSection/Section';
import { withElementEventTracker } from '../../../skyscanner-application/element-events';
import { ACTION_TYPE } from '../../../skyscanner-application/minievents/constants';

import type { ElementEventTracker } from '../../../skyscanner-application/types';
import type { Maybe } from '../../types';

import STYLES from './HotelsTitle.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  elementEventTracker: ElementEventTracker;
  isMobile?: boolean;
  layoutType?: Maybe<string>;
  toggleChevron?: Function;
  chevron?: boolean;
  className?: Maybe<string>;
  isSearchedCity?: boolean;
};

const HotelsTitle = ({
  chevron,
  className,
  elementEventTracker,
  i18n: { translate },
  isMobile,
  isSearchedCity,
  layoutType,
  toggleChevron = () => {},
}: Props) => {
  if (isMobile) {
    return (
      <Section
        actionLabel={
          translate('Viewed_hotels_viewAll_btn_text') ||
          translate('SectionHeader_link_seeAll')
        }
        actionLinkClassName={cls('HotelsTitle__viewAll')}
        href={HASH_VIEWED_HOTELS}
        sectionClassName={cls('HotelsTitle')}
        showActionLink
        onActionClick={() =>
          elementEventTracker.trackHotelsAction(
            ACTION_TYPE.VIEWED_HOTEL_VIEW_ALL_CLICK,
          )
        }
      >
        <BpkText textStyle={TEXT_STYLES.heading4}>
          {translate('Viewed_hotels_page_title')}
        </BpkText>
      </Section>
    );
  }

  if (layoutType) {
    return (
      <BpkText
        textStyle={TEXT_STYLES.heading2}
        tagName="h2"
        className={cls('HotelsTitle__desktop', className)}
      >
        {translate('Viewed_hotels_page_title')}
      </BpkText>
    );
  }

  const trackViewedHotelChevron = () => {
    elementEventTracker.trackHotelsAction(
      chevron
        ? ACTION_TYPE.VIEWED_HOTEL_HIDE_CLICK
        : ACTION_TYPE.VIEWED_HOTEL_SHOW_CLICK,
    );
    toggleChevron && toggleChevron();
  };

  if (isSearchedCity) {
    return (
      <div className={cls('HotelsTitle__desktop--searchPage', className)}>
        <BpkText tagName="p" textStyle={TEXT_STYLES.label1}>
          {translate('Viewed_history_recently_viewed_title')}
        </BpkText>
        <BpkButtonV2
          type={BUTTON_TYPES.link}
          data-test-id="viewed_history_tags_chevron"
          className={cls('HotelsTitle__trackedButton')}
          onClick={trackViewedHotelChevron}
        >
          <BpkText>
            {chevron
              ? translate('Viewed_hotels_page_hide')
              : translate('Viewed_hotels_page_show')}
          </BpkText>
        </BpkButtonV2>
      </div>
    );
  }
  return null;
};

HotelsTitle.defaultProps = {
  isMobile: false,
  chevron: true,
  layoutType: null,
  className: null,
};

export default withElementEventTracker(withI18n(HotelsTitle));
