export const LAYOUT_TYPE = {
  SWIPER: 'swiper',
  SLIDER: 'slider',
  PAGING: 'paging',
};

export const CLEAR_STATE = {
  SUCCESS: 'success',
  FAIL: 'fail',
  PROCESSING: 'processing',
  UNSET: 'unset',
};
