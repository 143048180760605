import React from 'react';

import { FormattedDistance } from '../../skyscanner-application/i18n';
import entityTypes from '../entity-types';

import type { DistanceOptions } from '../types';
import type { Distance as DistanceType } from '../../types/hotel-card';

type Props = {
  distance: DistanceType;
  isMobile?: boolean;
};

const getDistanceLabel = ({
  isMobile = false,
  referenceEntityName,
  referenceEntityType,
}: {
  referenceEntityName?: string;
  referenceEntityType?: string;
  isMobile?: boolean;
}) => {
  let options: DistanceOptions | undefined;
  let label;
  let distancePlaceholder;
  let data;
  if (referenceEntityType === entityTypes.HOTEL) {
    options = {
      kmString: 'Distance_label_fromHotelKm',
      milesString: 'Distance_label_fromHotelMiles',
    };
  }

  if (referenceEntityType === entityTypes.CITY) {
    label = 'Distance_label_fromCityCentre';
  }

  if (referenceEntityType === entityTypes.NAMED_ENTITY) {
    const showFromPlaceMore =
      (isMobile && referenceEntityName!.length > 30) ||
      referenceEntityName!.length > 40;

    if (showFromPlaceMore) {
      label = 'Distance_label_fromPlaceMore';
      distancePlaceholder = '0';
    } else {
      label = 'Distance_label_fromPlace';
      data = {
        place: referenceEntityName,
      };
    }
  }

  return {
    options,
    label,
    distancePlaceholder,
    data,
  };
};

const shouldRenderDistanceNode = (referenceEntityType?: string) =>
  referenceEntityType === entityTypes.HOTEL ||
  referenceEntityType === entityTypes.CITY ||
  referenceEntityType === entityTypes.NAMED_ENTITY;

const Distance = ({
  distance: {
    cityName,
    distanceMeters,
    referenceEntityName,
    referenceEntityType,
  },
  isMobile,
}: Props) => {
  const { data, distancePlaceholder, label, options } = getDistanceLabel({
    referenceEntityName,
    referenceEntityType,
    isMobile,
  });

  if (shouldRenderDistanceNode(referenceEntityType)) {
    return (
      <FormattedDistance
        distanceMeters={distanceMeters}
        options={options}
        label={label}
        distancePlaceholder={distancePlaceholder}
        data={data}
      />
    );
  }

  return <>{cityName}</>;
};

Distance.defaultProps = {
  isMobile: false,
};

export default Distance;
