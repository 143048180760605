import type { ReactElement } from 'react';
import React from 'react';

import debounce from 'lodash/debounce';

import BpkSmallChevronRight from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';
import BpkSmallChevronLeft from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-left';
import { cssModules, isRTL } from '@skyscanner/backpack-web/bpk-react-utils';
import { withButtonAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import {
  BpkButtonV2,
  BUTTON_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';

import ImageBullets from '../ImageBullets';

import STYLES from './Swiper.scss';

const cls = cssModules(STYLES);
const AlignedSmallChevronLeft = withButtonAlignment(BpkSmallChevronLeft);
const AlignedSmallChevronRight = withButtonAlignment(BpkSmallChevronRight);

const NextIcon = isRTL() ? AlignedSmallChevronLeft : AlignedSmallChevronRight;
const PrevIcon = isRTL() ? AlignedSmallChevronRight : AlignedSmallChevronLeft;

const INITIAL_INDEX = 0;
const INITIAL_TRANSLATE = 0;
const DEBOUNCE_TIME = 150;

export const getTransform = (offset: number) =>
  `translate3d(${isRTL() ? '' : '-'}${offset}px, 0, 0)`;

type Props = {
  children: ReactElement[];
  className?: string;
  lastPageClassName?: string;
  prevNavBtnClassName?: string;
  nextNavBtnClassName?: string;
  imageBulletsClassName?: string;
  activeBulletClassName?: string;
  bulletClassName?: string;
  buttonPosition?: string;
  itemsPerGroup: number;
  spaceBetween: number;
  fixedWidth: number;
  randomChildren?: boolean;
  enableMouseScroll?: boolean;
  needUpdate?: boolean;
  showImageBullets?: boolean;
  noMarginLeft?: boolean;
  buttonDisable?: boolean;
  renderLeftNav?: Function;
  renderRightNav?: Function;
  onClickNav?: Function;
};

type State = {
  swiperWidth: number;
  childWrapperWidth: number;
  hiddenPrevBtn: boolean;
  hiddenNextBtn: boolean;
};

const defaultProps = {
  itemsPerGroup: 3,
  spaceBetween: 0,
  buttonPosition: 'inner',
  fixedWidth: 0,
};

class Swiper extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  swiperRef: React.RefObject<HTMLDivElement>;

  childWrapperRef: React.RefObject<HTMLDivElement>;

  endIndex: number;

  swiperSingleChildWidth: number;

  currentChildLength: number;

  currentIndex: number;

  translateValue: number;

  touchStartX: number;

  debouncedGetWidth: any;

  constructor(props: Props) {
    super(props);

    this.swiperRef = React.createRef();
    this.childWrapperRef = React.createRef();

    this.state = {
      swiperWidth: 0,
      childWrapperWidth: 0,
      hiddenPrevBtn: true,
      hiddenNextBtn: false,
    };

    this.endIndex = 0;
    this.swiperSingleChildWidth = 0;
    this.currentChildLength = 0;
    this.currentIndex = INITIAL_INDEX;
    this.translateValue = INITIAL_TRANSLATE;
    this.touchStartX = 0;
  }

  componentDidMount() {
    const { enableMouseScroll } = this.props;

    this.getWidth();
    this.debouncedGetWidth = debounce(this.getWidth, DEBOUNCE_TIME);

    window.addEventListener('resize', this.debouncedGetWidth);

    // mouse scroll
    if (enableMouseScroll && this.swiperRef && this.swiperRef.current) {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
        this.swiperRef.current.addEventListener('mousewheel', this.scrollMouse);
      } else {
        this.swiperRef.current.addEventListener(
          'DOMMouseScroll',
          this.scrollMouse,
        );
      }
    }

    if (this.childWrapperRef && this.childWrapperRef.current) {
      // mobile/pad touch event
      this.childWrapperRef.current.addEventListener(
        'touchstart',
        this.touchStart,
      );
      this.childWrapperRef.current.addEventListener('touchend', this.touchEnd);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      childWrapperWidth: preChildWrapperWidth,
      swiperWidth: preSwiperWidth,
    } = this.state;
    const { children, needUpdate } = this.props;
    const { children: prevChildren } = prevProps;

    if (prevChildren && children) {
      if (
        children.length !== prevChildren.length ||
        children[0].key !== prevChildren[0].key
      ) {
        this.currentIndex = 0;
        if (this.childWrapperRef && this.childWrapperRef.current) {
          this.childWrapperRef.current.style.transform = `translate3d(0, 0, 0)`;
        }
        this.handleHiddenPrevBtn();
      }

      const { offsetWidth: swiperWidth } = this.swiperRef?.current || {};
      const { offsetWidth: childWrapperWidth } =
        this.childWrapperRef?.current || {};
      if (
        preSwiperWidth !== swiperWidth ||
        preChildWrapperWidth !== childWrapperWidth ||
        this.currentChildLength !== children.length ||
        prevChildren.length !== children.length ||
        needUpdate
      ) {
        this.getWidth();
        this.currentChildLength = children.length;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedGetWidth);

    if (this.props.enableMouseScroll) {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
        this.swiperRef?.current?.removeEventListener(
          'mousewheel',
          this.scrollMouse,
        );
      } else {
        this.swiperRef?.current?.removeEventListener(
          'DOMMouseScroll',
          this.scrollMouse,
        );
      }
    }

    if (this.childWrapperRef && this.childWrapperRef.current) {
      this.childWrapperRef.current.removeEventListener(
        'touchstart',
        this.touchStart,
      );
      this.childWrapperRef.current.removeEventListener(
        'touchend',
        this.touchEnd,
      );
    }
  }

  handleHiddenPrevBtn = () => {
    this.setState({
      hiddenPrevBtn: true,
      hiddenNextBtn: false,
    });
  };

  getCalculatedIndex = (fixedWidth = 0, wrapperWidth = 0, singleWidth = 0) => {
    const { spaceBetween } = this.props;
    const restWidth = fixedWidth - (singleWidth % (fixedWidth + spaceBetween));
    let index = 0;
    let tempWidth = wrapperWidth;

    if (restWidth < spaceBetween) {
      // Increase sliding flexibility
      index = Math.floor(
        wrapperWidth /
          (fixedWidth + spaceBetween) /
          Math.ceil(singleWidth / (fixedWidth + spaceBetween)),
      );
    } else {
      while (Math.floor(tempWidth / singleWidth) > 0) {
        tempWidth = tempWidth - singleWidth + fixedWidth - restWidth;
        index += 1;
      }
    }
    return index;
  };

  getWidth = () => {
    if (!this.swiperRef.current) {
      return;
    }
    const {
      children,
      fixedWidth,
      itemsPerGroup,
      noMarginLeft,
      randomChildren,
    } = this.props;
    const { offsetWidth: swiperWidth } = this.swiperRef.current || {};
    const { offsetWidth: childWrapperWidth = 0 } =
      this.childWrapperRef.current || {};

    this.swiperSingleChildWidth = swiperWidth / itemsPerGroup;
    if (fixedWidth && noMarginLeft) {
      this.endIndex = this.getCalculatedIndex(
        fixedWidth,
        childWrapperWidth,
        swiperWidth,
      );
    } else {
      this.endIndex = randomChildren
        ? Math.floor(childWrapperWidth / swiperWidth)
        : Math.ceil(children.length / Math.floor(itemsPerGroup)) - 1;
    }
    let hiddenNextBtn = true;
    if (childWrapperWidth >= swiperWidth) {
      hiddenNextBtn = false;
    }

    // fix end position when change swiper width
    if (
      childWrapperWidth >= swiperWidth &&
      this.currentIndex >= this.endIndex &&
      this.childWrapperRef &&
      this.childWrapperRef.current
    ) {
      hiddenNextBtn = true;
      this.childWrapperRef.current.style.transform = getTransform(
        childWrapperWidth - swiperWidth,
      );
    }

    if (
      hiddenNextBtn !== this.state.hiddenNextBtn ||
      swiperWidth !== this.state.swiperWidth ||
      childWrapperWidth !== this.state.childWrapperWidth
    ) {
      this.setState({
        hiddenNextBtn,
        swiperWidth,
        childWrapperWidth,
      });
    }
  };

  getWheelDelta = (event: any) => {
    if (event.wheelDelta) {
      // lazy load
      this.getWheelDelta = (e) => e.wheelDelta;

      // first load
      return event.wheelDelta;
    }
    // compatibile firfox
    this.getWheelDelta = (e) => e.detail;
    return -event.detail;
  };

  scrollMouse = (event: any) => {
    const delta = this.getWheelDelta(event);
    const type = delta > 0 ? 'prev' : 'next';

    this.clickNav(type);
    event.preventDefault();
  };

  touchStart = (event: TouchEvent) => {
    this.touchStartX = event.touches[0].pageX;
  };

  touchEnd = (event: TouchEvent) => {
    const touchEndX = event.changedTouches[0].pageX;
    const type = touchEndX - this.touchStartX > 0 ? 'prev' : 'next';

    if (Math.abs(touchEndX - this.touchStartX) > 5) {
      this.clickNav(type);
    }
  };

  clickNav = (type: string) => {
    const { childWrapperWidth, swiperWidth } = this.state;
    const {
      fixedWidth,
      itemsPerGroup,
      noMarginLeft,
      onClickNav,
      spaceBetween,
    } = this.props;
    const moveFactor = type === 'next' ? 1 : -1;

    const boundaryCondition = {
      START: this.currentIndex === 0,
      NEXT_LAST_STEP:
        type === 'next' && this.currentIndex === this.endIndex - 1,
      END: this.currentIndex === this.endIndex,
      PREV_LAST_STEP: type === 'prev' && this.currentIndex === 1,
    };
    const restWidth = fixedWidth - (swiperWidth % (fixedWidth + spaceBetween));

    if (
      (type === 'prev' && this.currentIndex > INITIAL_INDEX) ||
      (type === 'next' && this.currentIndex < this.endIndex)
    ) {
      if (fixedWidth && noMarginLeft) {
        if (restWidth < spaceBetween) {
          this.translateValue +=
            moveFactor *
            Math.ceil(swiperWidth / (fixedWidth + spaceBetween)) *
            (fixedWidth + spaceBetween);
        } else {
          this.translateValue +=
            Math.floor(swiperWidth / (fixedWidth + spaceBetween)) *
            (fixedWidth + spaceBetween) *
            moveFactor;
        }
      } else if (boundaryCondition.START) {
        this.translateValue =
          itemsPerGroup % 1 === 0
            ? swiperWidth + spaceBetween
            : swiperWidth - (1 / 2) * this.swiperSingleChildWidth;
      } else {
        this.translateValue =
          itemsPerGroup % 1 === 0
            ? this.translateValue + (swiperWidth + spaceBetween) * moveFactor
            : this.translateValue +
              ((swiperWidth - spaceBetween * Math.ceil(itemsPerGroup - 1)) /
                itemsPerGroup +
                spaceBetween) *
                Math.floor(itemsPerGroup) *
                moveFactor;
      }

      if (boundaryCondition.NEXT_LAST_STEP) {
        if (fixedWidth && noMarginLeft) {
          this.translateValue = childWrapperWidth - swiperWidth;
        } else {
          this.translateValue = childWrapperWidth - swiperWidth - spaceBetween;
        }
      }

      if (boundaryCondition.END) {
        if (fixedWidth && noMarginLeft) {
          this.translateValue -= spaceBetween;
        } else {
          this.translateValue =
            itemsPerGroup % 1 === 0
              ? childWrapperWidth - (swiperWidth + spaceBetween / 2) * 2
              : childWrapperWidth -
                swiperWidth * 2 +
                (1 / 2) * this.swiperSingleChildWidth;
        }
      }

      if (boundaryCondition.PREV_LAST_STEP) {
        this.translateValue = 0;
      }

      if (this.childWrapperRef && this.childWrapperRef.current) {
        this.childWrapperRef.current.style.transform = getTransform(
          this.translateValue,
        );
      }

      this.currentIndex += moveFactor;
      const generalObject = {
        hiddenPrevBtn: false,
        hiddenNextBtn: false,
      };
      if (this.currentIndex === INITIAL_INDEX) {
        generalObject.hiddenPrevBtn = true;
      }
      if (this.currentIndex === this.endIndex) {
        generalObject.hiddenNextBtn = true;
      }

      this.setState(generalObject);
      if (onClickNav) {
        onClickNav(this.currentIndex);
      }
    }
  };

  render() {
    const {
      activeBulletClassName,
      bulletClassName,
      buttonDisable,
      buttonPosition,
      children,
      className,
      fixedWidth,
      imageBulletsClassName,
      itemsPerGroup,
      lastPageClassName,
      nextNavBtnClassName,
      noMarginLeft,
      prevNavBtnClassName,
      randomChildren,
      renderLeftNav,
      renderRightNav,
      showImageBullets,
      spaceBetween,
    } = this.props;

    if (!children || !Number(itemsPerGroup)) {
      return null;
    }

    const { hiddenNextBtn, hiddenPrevBtn, swiperWidth } = this.state;

    // If randomChildren is false and you wrap react custom component but not html standard element directly,
    // you must guarantee that the html standard element of swiper after rendered can get the style from here
    const leftMargin = isRTL() ? `${spaceBetween}px` : 0;
    const rightMargin = isRTL() ? 0 : `${spaceBetween}px`;
    const leftHalfMargin = isRTL() ? `${spaceBetween / 2}px` : 0;
    const rightHalfMargin = isRTL() ? 0 : `${spaceBetween / 2}px`;

    const childrenLength = children.length;
    const createChildrenStyle = (index?: number) => {
      const isFirstItem = index === 0;
      const isLastItem = index === children.length - 1;
      let margin = `0 ${spaceBetween / 2}px`;
      if (isLastItem) {
        margin = `0 ${leftHalfMargin} 0 ${rightHalfMargin}`;
      }
      if (isFirstItem) {
        margin = `0 ${rightHalfMargin} 0 ${leftHalfMargin}`;
      }
      if (noMarginLeft) {
        margin = `0 ${rightMargin} 0 ${leftMargin}`;
      }

      let width = `${
        (swiperWidth - spaceBetween * Math.ceil(itemsPerGroup - 1)) /
        itemsPerGroup
      }px`;
      if (fixedWidth) {
        width = `${fixedWidth}px`;
      }

      return randomChildren
        ? {
            display: 'inline-block',
          }
        : {
            margin,
            width,
            flexShrink: 0,
            display: 'inline-block',
            minWidth: 0,
            maxWidth: '9999px',
          };
    };

    let rebuildChildren = [];
    if (childrenLength) {
      rebuildChildren = children.map((child, index) =>
        React.cloneElement(child, {
          style: createChildrenStyle(index),
        }),
      );
    } else {
      // @ts-expect-error missing properties
      rebuildChildren = React.cloneElement(children, {
        style: createChildrenStyle(),
      });
    }

    const navBtnCommonProps = {
      iconOnly: true,
      type: BUTTON_TYPES.secondary,
    };

    return (
      <div
        ref={this.swiperRef}
        className={cls('Swiper', className, hiddenNextBtn && lastPageClassName)}
        style={{
          position: buttonPosition === 'outer' ? 'static' : 'relative',
        }}
      >
        <section ref={this.childWrapperRef} className={STYLES.Swiper__wrapper}>
          {rebuildChildren}
        </section>
        {!!this.endIndex && this.endIndex > 0 && showImageBullets && (
          <ImageBullets
            className={imageBulletsClassName}
            activeBulletClassName={activeBulletClassName}
            bulletClassName={bulletClassName}
            currentIndex={this.currentIndex}
            totalBullets={this.endIndex + 1}
          />
        )}

        {renderLeftNav ? (
          renderLeftNav(() => this.clickNav('prev'), hiddenPrevBtn)
        ) : (
          <BpkButtonV2
            onClick={() => this.clickNav('prev')}
            className={cls(
              'Swiper__prevBtn',
              'Swiper__navBtn',
              buttonDisable && childrenLength > itemsPerGroup
                ? hiddenPrevBtn && 'Swiper__prevBtnDisable'
                : hiddenPrevBtn && 'Swiper__prevBtnHide',
              prevNavBtnClassName,
            )}
            disabled={hiddenPrevBtn && buttonDisable}
            {...navBtnCommonProps}
          >
            <PrevIcon />
          </BpkButtonV2>
        )}

        {renderRightNav ? (
          renderRightNav(() => this.clickNav('next'), hiddenNextBtn)
        ) : (
          <BpkButtonV2
            onClick={() => this.clickNav('next')}
            className={cls(
              'Swiper__nextBtn',
              'Swiper__navBtn',
              buttonDisable && childrenLength > itemsPerGroup
                ? hiddenNextBtn && 'Swiper__nextBtnDisable'
                : hiddenNextBtn && 'Swiper__nextBtnHide',
              nextNavBtnClassName,
            )}
            disabled={hiddenNextBtn && buttonDisable}
            {...navBtnCommonProps}
          >
            <NextIcon />
          </BpkButtonV2>
        )}
      </div>
    );
  }
}

export default Swiper;
