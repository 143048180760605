/*
 * ImageBullets
 *
 * This component is used to display multiple pictures. If there are more than 5 pictures, they can also be displayed through animation.
 * Used in conjunction with react-image-gallery in the hotel-website project.
 *
 */

import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import STYLES from './ImageBullets.scss';

type Props = {
  currentIndex: number;
  totalBullets: number;
  className?: string;
  activeBulletClassName?: string;
  bulletClassName?: string;
};

const defaultProps = {
  className: null,
  activeBulletClassName: null,
  bulletClassName: null,
};

const cls = cssModules(STYLES);

const SMALL_BULLET_WIDTH = 8;
const LARGEST_STATIC_LENGTH = 6;
const SCROLL_BULLETS_DISPLAY_AMOUNT = 5;
const START_SCROLL_INDEX = Math.ceil(SCROLL_BULLETS_DISPLAY_AMOUNT / 2);
const LONGEST_BULLETS_AMOUNT = 7;
const TRANSITION_TIME = 210;
const TRANSITION_DELAY = 220;

class ImageBullets extends React.Component<Props> {
  imageBulletsRef: any;

  totalBulletsLength: number;

  bulletsArray: number[];

  innerCurrentIndex: number;

  timeoutID: any;

  largestImageIndex: number;

  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.imageBulletsRef = React.createRef();

    const { totalBullets } = this.props;

    this.totalBulletsLength =
      totalBullets > LARGEST_STATIC_LENGTH
        ? LONGEST_BULLETS_AMOUNT
        : totalBullets;
    this.bulletsArray = [];
    for (let i = 0; i < this.totalBulletsLength; i += 1) {
      this.bulletsArray.push(i);
    }

    this.innerCurrentIndex = 0;
    this.timeoutID = null;
    this.largestImageIndex = totalBullets - 1;
  }

  shouldComponentUpdate(nextProps: Props) {
    if (
      nextProps.currentIndex !== this.props.currentIndex ||
      nextProps.totalBullets !== this.props.totalBullets
    ) {
      if (this.totalBulletsLength > LARGEST_STATIC_LENGTH) {
        this.scrollBullets(
          nextProps.currentIndex - this.props.currentIndex,
          nextProps.currentIndex,
        );
        this.totalBulletsLength = LONGEST_BULLETS_AMOUNT;
      } else {
        this.innerCurrentIndex = nextProps.currentIndex;
        this.totalBulletsLength = nextProps.totalBullets;
      }

      if (nextProps.totalBullets !== this.props.totalBullets) {
        this.innerCurrentIndex = 0;
        this.bulletsArray = [];
        for (let i = 0; i < this.totalBulletsLength; i += 1) {
          this.bulletsArray.push(i);
        }
      }

      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  scrollBullets = (moveFactor: number, outerCurrentIndex: number) => {
    const { totalBullets } = this.props;
    const { current: imageBulletsNode } = this.imageBulletsRef;

    imageBulletsNode.style.transition = `transform ${TRANSITION_TIME}ms ease-in`;
    this.innerCurrentIndex += moveFactor;

    if (outerCurrentIndex < START_SCROLL_INDEX) {
      if (outerCurrentIndex === 0) {
        this.innerCurrentIndex = 0;
      }
      imageBulletsNode.style.transform = `translate3d(0, 0, 0)`;
    }

    if (
      (moveFactor > 0 && outerCurrentIndex === START_SCROLL_INDEX) ||
      (moveFactor < 0 &&
        outerCurrentIndex === this.largestImageIndex - START_SCROLL_INDEX)
    ) {
      imageBulletsNode.style.transform = `translate3d(-${SMALL_BULLET_WIDTH}px, 0, 0)`;
    }

    if (
      (moveFactor > 0 &&
        outerCurrentIndex > START_SCROLL_INDEX &&
        outerCurrentIndex < totalBullets - START_SCROLL_INDEX) ||
      (moveFactor < 0 &&
        outerCurrentIndex > START_SCROLL_INDEX - 1 &&
        outerCurrentIndex < this.largestImageIndex - START_SCROLL_INDEX)
    ) {
      this.innerCurrentIndex -= moveFactor;
      imageBulletsNode.style.transform = `translate3d(-${
        moveFactor > 0 ? SMALL_BULLET_WIDTH * 2 : 0
      }px, 0, 0)`;

      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        imageBulletsNode.style.transition = `transform 0s`;
        imageBulletsNode.style.transform = `translate3d(-${SMALL_BULLET_WIDTH}px, 0, 0)`;
      }, TRANSITION_DELAY);
    }

    if (outerCurrentIndex > this.largestImageIndex - START_SCROLL_INDEX) {
      if (outerCurrentIndex === this.largestImageIndex) {
        this.innerCurrentIndex = LONGEST_BULLETS_AMOUNT - 1;
      }
      imageBulletsNode.style.transform = `translate3d(-${
        SMALL_BULLET_WIDTH * 2
      }px, 0, 0)`;
    }
  };

  render() {
    const { activeBulletClassName, bulletClassName, className, totalBullets } =
      this.props;

    if (!totalBullets || totalBullets === 1) {
      return null;
    }

    return (
      <section
        className={cls(
          'ImageBullets',
          totalBullets === LARGEST_STATIC_LENGTH &&
            'ImageBullets__largestStaticLength',
          className,
        )}
      >
        <section
          ref={this.imageBulletsRef}
          className={cls('ImageBullets__container')}
        >
          {this.bulletsArray.map((item, index) => (
            <span
              key={item}
              className={cls(
                'ImageBullets__bullet',
                index === this.innerCurrentIndex && 'ImageBullets__bullet--lg',
                index === this.innerCurrentIndex && activeBulletClassName,
                index !== this.innerCurrentIndex && bulletClassName,
                Math.abs(index - this.innerCurrentIndex) === 1 &&
                  'ImageBullets__bullet--md',
                Math.abs(index - this.innerCurrentIndex) > 1 &&
                  'ImageBullets__bullet--sm',
              )}
            />
          ))}
        </section>
      </section>
    );
  }
}

export default ImageBullets;
