const scoreBracketsForReview = [
  { threshold: 0, summary: 'rating_below_average' },
  { threshold: 1.5, summary: 'rating_average' },
  { threshold: 3, summary: 'rating_satisfactory' },
  { threshold: 3.5, summary: 'rating_good' },
  { threshold: 4, summary: 'rating_very_good' },
  { threshold: 4.5, summary: 'rating_excellent' },
  { threshold: 5, summary: 'rating_with_honors' },
];

const scoreSummaryForReview = (num: number) =>
  scoreBracketsForReview.reduce(
    (foundSummary: string | undefined, value, idx) => {
      const { summary, threshold } = value;
      const maxIdx = scoreBracketsForReview.length - 1;
      const gapIdx = Math.min(maxIdx, idx + 1);
      const gap =
        scoreBracketsForReview[gapIdx].threshold -
        scoreBracketsForReview[idx].threshold;

      if (foundSummary) {
        return foundSummary;
      }

      if (num - gap >= threshold && idx < maxIdx) {
        return undefined;
      }
      return summary;
    },
    undefined,
  );

export { scoreBracketsForReview, scoreSummaryForReview };
