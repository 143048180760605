import React from 'react';

import throttle from 'lodash/throttle';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { Nullable } from 'common/src/types/utils';

import lowRes from '../img/defaultProgressiveImage.jpg';

import STYLES from './ProgressivePictureButton.scss';

type Props = {
  src: string;
  alt: string;
  className?: string;
  lowQualityClassName?: string;
  containerButtonClassName?: string;
  dataTestId?: Nullable<string>;
  showShimmerAnimation?: boolean;
  ariaHidden?: boolean;
  lazyLoad?: boolean;
  onClick?: any;
  sources: Array<{
    media?: string;
    srcSet: string[];
  }>;
};

type State = {
  imageQuality: string;
  inViewport: boolean;
};

const defaultProps = {
  showShimmerAnimation: false,
  dataTestId: null,
  lazyLoad: false,
  ariaHidden: false,
  onClick: () => {},
};

const cls = cssModules(STYLES);
const THROTTLE_TIME = 200;

class ProgressivePictureButton extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  imageRef: any;

  imageContainerRef: any;

  throttleLazyLoadImage: any;

  constructor(props: Props) {
    super(props);

    this.imageRef = React.createRef();
    this.imageContainerRef = React.createRef();
    this.state = {
      imageQuality: 'low',
      inViewport: false,
    };
  }

  componentDidMount() {
    const { lazyLoad } = this.props;

    if (lazyLoad) {
      // first time load
      this.lazyLoadImage();

      this.throttleLazyLoadImage = throttle(this.lazyLoadImage, THROTTLE_TIME);

      window.addEventListener('scroll', this.throttleLazyLoadImage, true);
      window.addEventListener('resize', this.throttleLazyLoadImage);
    } else {
      this.handleImageLoadedBeforeDidMount();
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.props.src !== nextProps.src ||
      this.state.inViewport !== nextState.inViewport ||
      this.state.imageQuality !== nextState.imageQuality
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleLazyLoadImage);
    window.removeEventListener('resize', this.throttleLazyLoadImage);
  }

  handleImageLoadedBeforeDidMount = () => {
    if (this.imageRef.current && this.imageRef.current.complete) {
      this.setState({
        imageQuality: 'high',
      });
    }
  };

  lazyLoadImage = () => {
    if (
      (!this.imageRef.current ||
        (this.imageRef.current && !this.imageRef.current.complete)) &&
      this.imageContainerRef.current
    ) {
      // if image has loaded, not excuted this code again
      const clientHeight = window.innerHeight;
      const bound = this.imageContainerRef.current.getBoundingClientRect();

      if (bound.top <= clientHeight) {
        this.setState({
          inViewport: true,
        });
      }
    }
  };

  onImageLoad = () => {
    this.setState({
      imageQuality: 'high',
    });
  };

  render() {
    const { imageQuality, inViewport } = this.state;
    const {
      alt,
      ariaHidden,
      className,
      containerButtonClassName,
      dataTestId,
      lazyLoad,
      lowQualityClassName,
      onClick,
      showShimmerAnimation,
      sources,
      src,
    } = this.props;

    const supportImageResize = src?.includes('_WxH');

    const imageNode = (
      <picture
        className={cls('ProgressivePictureButton__img')}
        style={{
          opacity: imageQuality === 'high' ? 1 : 0,
        }}
      >
        {supportImageResize &&
          sources.map((source) => (
            <source
              key={source.srcSet.join(',')}
              media={source.media}
              srcSet={source.srcSet.join(', ')}
            />
          ))}
        <img
          className={className}
          src={src}
          alt={alt}
          ref={this.imageRef}
          onLoad={this.onImageLoad}
        />
      </picture>
    );

    return (
      <div
        onKeyUp={() => {}}
        role="button"
        tabIndex={0}
        data-test-id={dataTestId}
        aria-hidden={ariaHidden}
        className={cls('ProgressivePictureButton', containerButtonClassName)}
        onClick={onClick}
        ref={this.imageContainerRef}
      >
        {!showShimmerAnimation && (
          <img
            className={cls(
              className,
              lowQualityClassName,
              'ProgressivePictureButton__img',
              'ProgressivePictureButton__lowQuality',
            )}
            src={lowRes}
            alt={alt}
            style={{
              opacity: imageQuality === 'low' ? 1 : 0,
            }}
          />
        )}

        {showShimmerAnimation && (
          <section
            className={cls(
              className,
              'ProgressivePictureButton__img',
              'ProgressivePictureButton__shimmer',
            )}
            style={{
              opacity: imageQuality === 'low' ? 1 : 0,
            }}
          />
        )}
        {lazyLoad && inViewport && imageNode}
        {!lazyLoad && imageNode}
      </div>
    );
  }
}

export default ProgressivePictureButton;
