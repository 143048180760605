import type {
  City,
  Cities,
  HotelCards,
  HotelsGroupsByCity,
  ViewedHotelFilter,
  HotelsListFilteredByAllCities,
} from './types';

export const getHotelsGroupByCities = (hotels: HotelCards, cities?: Cities) => {
  const hotelsGroupByCity: HotelsGroupsByCity = [];
  cities?.forEach((city) => {
    // @ts-expect-error TS2339: Property 'cityId' does not exist on type 'HotelCard'.
    // TODO: we need to update the schema of the endpoint to make it matches HotelCard so that it could be reusable
    const hotelsInCity = hotels.filter(({ cityId }) => cityId === city.id);
    if (hotelsInCity.length > 0) {
      hotelsGroupByCity.push({ city, hotelCards: hotelsInCity });
    }
  });
  return hotelsGroupByCity;
};

export const getHotelsFilteredByCity = (
  hotels: HotelCards,
  filter: ViewedHotelFilter,
  city: City,
) => {
  const hotelsByCity: HotelsListFilteredByAllCities = [];
  const dates = filter.viewDates || [];
  dates.forEach((day) => {
    // @ts-expect-error TS2339: Property 'viewDate' does not exist on type 'HotelCard'.
    const hotelsByDate = hotels.filter(({ viewDate }) => viewDate === day.id);
    const hotelsInCities = getHotelsGroupByCities(hotelsByDate, [city]);
    if (hotelsInCities.length > 0) {
      hotelsByCity.push({ viewDate: day, hotels: hotelsInCities });
    }
  });

  return hotelsByCity;
};

export const getHotelsFilteredByAllCities = (
  hotels: HotelCards,
  filter: ViewedHotelFilter,
) => {
  const hotelsInDates: HotelsListFilteredByAllCities = [];
  if (!filter) {
    return hotelsInDates;
  }
  const { cities } = filter;
  const dates = filter.viewDates || [];
  dates.forEach((day) => {
    // @ts-expect-error TS2339: Property 'viewDate' does not exist on type 'HotelCard'.
    const hotelsByDate = hotels.filter(({ viewDate }) => viewDate === day.id);
    const hotelsInCities = getHotelsGroupByCities(hotelsByDate, cities);
    hotelsInDates.push({ viewDate: day, hotels: hotelsInCities });
  });

  return hotelsInDates;
};

const getUniqueHotelCards = (hotels: HotelCards) => {
  if (!hotels.length) {
    return [];
  }

  // @ts-expect-error TS2769: No overload matches this call.
  const hotelIds = new Set(hotels.reduce((acc, { id }) => [...acc, id], []));

  const uniqueHotelCards: HotelCards = [];
  hotelIds.forEach((id) => {
    const firstHotelForId = hotels.find(({ id: hotelID }) => hotelID === id);
    if (firstHotelForId) {
      uniqueHotelCards.push(firstHotelForId);
    }
  });

  return uniqueHotelCards;
};

export const getHotelCardsSortedByAllCities = (
  hotelsGroupByAllCities: HotelsListFilteredByAllCities,
) => {
  // @ts-expect-error TS2769: No overload matches this call.
  const hotels = hotelsGroupByAllCities.reduce((acc, curr) => {
    const temp = curr?.hotels?.reduce(
      // @ts-expect-error TS2769: No overload matches this call.
      (accInner, currInner) => [...accInner, ...currInner.hotelCards],
      [],
    );
    // @ts-expect-error TS2461: Type 'HotelsGroupByCity | undefined' is not an array type.
    return [...acc, ...temp];
  }, []);

  // @ts-expect-error TS2345: Argument of type 'HotelsFilteredByAllCities' is not assignable to parameter of type 'HotelCards'.
  return getUniqueHotelCards(hotels);
};

export const getSpecificCities = (cities: Cities = []) =>
  cities.filter((city) => city.type === 'City' || city.type === 'Island');
