import type { Stay } from 'common/src/types/utils';

import {
  getHotelCardsSortedByAllCities,
  getHotelsFilteredByAllCities,
  getHotelsFilteredByCity,
} from './viewed-hotels-helper';
import { CLEAR_STATE } from './viewed-hotel-constant';

import type { BackendGateway } from '../../types/backend-gateway';
import type {
  City,
  HotelCards,
  HotelsListFilteredByAllCities,
  ViewedHotelFilter,
  ViewedHotelsResult,
} from './types';
import type { ViewedHotelsResponse } from '../../types/viewed-hotels-response';
import type { Metrics } from '../../skyscanner-application/types';

const searchViewedError = (searchResult: ViewedHotelsResponse) =>
  !searchResult || (searchResult && searchResult.error);

const searchViewedEmpty = (searchResult: ViewedHotelsResponse) =>
  searchResult &&
  !searchResult.error &&
  searchResult.hotelCards &&
  searchResult.hotelCards.length === 0;

const ViewedHotelsStore = ({
  backendGateway,
  metrics,
  search: initialSearch,
}: {
  backendGateway: BackendGateway;
  metrics: Metrics;
  search: { stay: Stay; priceType?: string };
}) => {
  let callbacks: Function[] = [];
  const currentSearch = initialSearch;
  let initialGroupedHotels: HotelsListFilteredByAllCities = [];
  let initialSortedHotels: HotelCards = [];
  let resultFilter: ViewedHotelFilter;
  let resultHotelCards: HotelCards = [];
  let searchResult: ViewedHotelsResponse;
  let filterCity: City;
  // @ts-expect-error missing properties
  let allSearchResult: ViewedHotelsResult = {
    selectedChipId: 'all',
    clearStatus: CLEAR_STATE.UNSET,
    isFinished: false,
    isFromStayChange: false,
    ...currentSearch,
  };

  const getFilterChangeDate = (city: City) => {
    let groupedHotels = initialGroupedHotels;
    let sortedHotels = initialSortedHotels;

    const isSpecificCity = city.id !== 'all';
    if (isSpecificCity) {
      const hotelsByCity = getHotelsFilteredByCity(
        resultHotelCards,
        resultFilter,
        city,
      );
      const sortHotelsByCity = getHotelCardsSortedByAllCities(hotelsByCity);
      groupedHotels = hotelsByCity;
      sortedHotels = sortHotelsByCity;
    }

    allSearchResult = {
      ...allSearchResult,
      ...searchResult,
      groupedHotels,
      sortedHotels,
    };
  };

  return {
    addListener: (cb: Function) => {
      callbacks.push(cb);
    },

    removeListener: (cbToRemove: Function) => {
      callbacks = callbacks.filter((cb) => cb !== cbToRemove);
    },

    getCurrentSearch: () => currentSearch,

    getAllSearchResult: () => allSearchResult,

    onFilterChangeData: (city: City) => {
      filterCity = city;
      allSearchResult = {
        ...allSearchResult,
        selectedChipId: filterCity.id,
      };
      getFilterChangeDate(filterCity);

      callbacks.forEach((cb) => cb());
    },

    searchViewedHotels: async () => {
      const startTime = Date.now();
      searchResult = await backendGateway.searchViewedHotels({
        ...currentSearch,
      });
      if (searchViewedError(searchResult)) {
        allSearchResult = {
          ...allSearchResult,
          ...currentSearch,
          groupedHotels: [],
          sortedHotels: [],
          isFinished: true,
        };
        metrics.hotelsHunterFailure();
      } else if (searchViewedEmpty(searchResult)) {
        allSearchResult = {
          ...allSearchResult,
          ...currentSearch,
          groupedHotels: [],
          sortedHotels: [],
          isFinished: true,
        };
        metrics.hotelsHunterEmpty();
      } else {
        const timeTaken = Date.now() - startTime;
        metrics.hotelsHunterSuccess(timeTaken);

        resultHotelCards = searchResult.hotelCards;
        resultFilter = searchResult.filter;
        const groupedHotelCards = getHotelsFilteredByAllCities(
          resultHotelCards,
          resultFilter,
        );
        const sortedHotelCards =
          getHotelCardsSortedByAllCities(groupedHotelCards);

        initialGroupedHotels = groupedHotelCards;
        initialSortedHotels = sortedHotelCards;

        allSearchResult = {
          ...allSearchResult,
          ...searchResult,
          ...currentSearch,
          groupedHotels: groupedHotelCards,
          sortedHotels: sortedHotelCards,
          isFinished: true,
        };

        const { selectedChipId } = allSearchResult;
        if (selectedChipId !== 'all') {
          // @ts-expect-error missing properties
          getFilterChangeDate({ id: selectedChipId });
        }
      }

      callbacks.forEach((cb) => cb());
    },

    deleteHotels: async (_all: any, histories: any) => {
      const hunterResponse = await backendGateway.deleteHistoryHotels({
        histories,
      });

      let deleteStatus = CLEAR_STATE.UNSET;
      if (hunterResponse) {
        const {
          status: { code },
        } = hunterResponse;

        switch (code) {
          case 200:
            deleteStatus = CLEAR_STATE.SUCCESS;
            break;
          case 400:
          case 401:
          case 403:
          case 500:
            deleteStatus = CLEAR_STATE.FAIL;
            break;
          default:
            deleteStatus = CLEAR_STATE.PROCESSING;
            break;
        }
      } else {
        deleteStatus = CLEAR_STATE.FAIL;
      }

      if (deleteStatus !== allSearchResult.clearStatus) {
        allSearchResult = {
          ...allSearchResult,
          clearStatus: deleteStatus,
        };
      }

      if (deleteStatus === CLEAR_STATE.SUCCESS) {
        allSearchResult = {
          ...allSearchResult,
          groupedHotels: [],
          sortedHotels: [],
        };
      }
      callbacks.forEach((cb) => cb());
      return deleteStatus;
    },
  };
};

export default ViewedHotelsStore;
