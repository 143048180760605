import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkRating, {
  RATING_SIZES,
} from '@skyscanner/backpack-web/bpk-component-rating';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../skyscanner-application/i18n';
import { scoreSummaryForReview } from '../../services/score';
import { RATING_KEY_MAP } from '../rating-key-map';
import { getId, getAriaHidden } from '../../utils/a11y';

import type { Maybe } from '../types';

import STYLES from './ReviewRating.scss';

type Props = {
  i18n: I18nService;
  className?: Maybe<string>;
  score: number;
  count?: Maybe<number>;
  image?: Maybe<string>;
  large?: boolean;
  index?: number;
};

const cls = cssModules(STYLES);

const ReviewRating = ({
  className,
  count,
  i18n,
  image,
  index,
  large,
  score,
}: Props) => {
  if (!score || !count) {
    return null;
  }

  const formatScore = i18n.formatNumber(score, 1);
  const formatCount = i18n.formatNumber(count, 0);
  const ratingKey = scoreSummaryForReview(score);

  const ariaLabel = image
    ? i18n.translate('aria_trip_advisor_review_rating', {
        score,
        count,
      })
    : i18n.translate('aria_trip_com_review_rating', {
        desc: ratingKey ? i18n.translate(RATING_KEY_MAP[ratingKey]) : '',
        score,
        count,
      });

  let title = null;
  if (image) {
    title = (
      <img
        src={image}
        alt={score.toString()}
        className={STYLES.ReviewRating__image}
      />
    );
  } else if (ratingKey) {
    title = i18n.translate(RATING_KEY_MAP[ratingKey]);
  }

  const subtitle =
    count > 9
      ? i18n.translate('Reviews_label_X', {
          0: formatCount,
        })
      : i18n.translate(`Reviews_label_${count}`);

  return (
    <BpkRating
      className={cls('ReviewRating', className)}
      id={getId('review_rating', index)}
      ariaLabel={ariaLabel}
      aria-hidden={getAriaHidden(index)}
      value={formatScore}
      title={title}
      subtitle={subtitle}
      showScale={!image}
      size={large ? RATING_SIZES.large : RATING_SIZES.base}
    />
  );
};

ReviewRating.defaultProps = {
  className: null,
  image: null,
  large: false,
  count: null,
};

export default withI18n(ReviewRating);
