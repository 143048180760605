import React from 'react';

import PageTypes from 'common/src/pageTypes';
import type { I18nService } from 'common/src/types/i18n';
import type { Nullable, Stay } from 'common/src/types/utils';

import { withMetrics } from '../../skyscanner-application/application-metrics';
import { withElementEventTracker } from '../../skyscanner-application/element-events';
import { withI18n } from '../../skyscanner-application/i18n';
import { ACTION_TYPE } from '../../skyscanner-application/minievents/constants';
import { buildAdditionalInfoViewedHotelsDataLoaded } from '../../skyscanner-application/minievents/hotels-action';
import BpkBreakpoint, { BREAKPOINTS } from '../Common/BpkBreakpoint';
import { withBackendContext } from '../backend-context';

import ViewedHotelsDesktopLayout from './ViewedHotelsDesktopLayout';
import ViewedHotelsStore from './viewed-hotel-store';
import { getSpecificCities } from './viewed-hotels-helper';

import type {
  ElementEventTracker,
  Metrics,
} from '../../skyscanner-application/types';
import type { BackendGateway } from '../../types/backend-gateway';
import type {
  City,
  HotelCards,
  ViewedHotelsData,
  Cities,
  ViewedHotelsResult,
} from './types';

type Props = {
  i18n: I18nService;
  elementEventTracker: ElementEventTracker;
  backendGateway: BackendGateway;
  metrics: Metrics;
  stay: Stay;
  viewedHotelsDesktopEnable?: boolean;
  layoutType?: Nullable<string>;
  priceType?: string;
  destination?: string;
  viewedHotelsStack?: HotelCards;
};

export type State = {
  specificCities: Cities;
  hasSearchedCity: boolean;
  searchCycleId?: string;
} & ViewedHotelsResult;

const defaultProps = {
  priceType: 'price-per-night',
  viewedHotelsStack: [],
};

class ViewedHotelsContainer extends React.Component<Props, State> {
  store: ReturnType<typeof ViewedHotelsStore>;

  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.store = ViewedHotelsStore({
      backendGateway: this.props.backendGateway,
      search: { stay: this.props.stay, priceType: this.props.priceType },
      metrics: this.props.metrics,
    });

    this.state = {
      ...this.store.getAllSearchResult(),
      specificCities: [],
      hasSearchedCity: false,
    };
  }

  componentDidMount() {
    this.store.addListener(this.onNewData);
    this.store.searchViewedHotels();
  }

  componentWillUnmount() {
    this.store.removeListener(this.onNewData);
  }

  onNewData = () => {
    const result = this.store.getAllSearchResult();
    this.setState(result);

    const { filter, groupedHotels, isFinished, isFromStayChange } = result;
    const { destination, elementEventTracker, layoutType } = this.props;
    if (!isFromStayChange && isFinished && groupedHotels.length > 0) {
      const { cities } = filter || [];
      const specificCities = getSpecificCities(cities) || [];
      const destinationCity = cities?.find(
        (city) => city.id === `${destination}`,
      );
      const hasSearchedCity = !!(specificCities.length > 0 && destinationCity);
      this.setState({
        specificCities,
        hasSearchedCity,
      });

      elementEventTracker.trackHotelsAction(
        ACTION_TYPE.VIEWED_HOTELS_DATA_LOADED,
        buildAdditionalInfoViewedHotelsDataLoaded({
          pageType: layoutType ? PageTypes.HOME : PageTypes.SEARCH,
          hasSearchedCity,
        }),
      );
    }
  };

  onSelectedChipChange = (city: City) => {
    this.store.onFilterChangeData(city);
  };

  onHotelCardClicked = (clickProps: any) => {
    const { i18n, metrics } = this.props;
    const { searchCycleId } = this.state;

    metrics.searchResultSelected({
      ...clickProps,
      currency: i18n.culture.currency,
      searchId: searchCycleId,
      isViewedHotel: true,
    });
  };

  render() {
    const {
      destination,
      layoutType,
      viewedHotelsDesktopEnable,
      viewedHotelsStack,
    } = this.props;
    const { groupedHotels, hasSearchedCity, specificCities } = this.state;
    const isAvailableData = groupedHotels && groupedHotels.length > 0;

    const viewedHotelsData: ViewedHotelsData = {
      ...this.state,
      onSelectedChipChange: this.onSelectedChipChange,
      onCardClick: this.onHotelCardClicked,
      isAvailableData,
    };

    return (
      <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
        {(isActive: boolean) =>
          !isActive &&
          viewedHotelsDesktopEnable && (
            <ViewedHotelsDesktopLayout
              viewedHotelsData={viewedHotelsData}
              layoutType={layoutType}
              destination={destination}
              onCardClicked={this.onHotelCardClicked}
              viewedHotelsStack={viewedHotelsStack}
              specificCities={specificCities}
              hasSearchedCity={hasSearchedCity}
            />
          )
        }
      </BpkBreakpoint>
    );
  }
}

export default withElementEventTracker(
  withI18n(withMetrics(withBackendContext(ViewedHotelsContainer))),
);
