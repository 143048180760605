import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import type { Nullable } from 'common/src/types/utils';

import HotelsFilter from '../HotelsFilter';
import HotelsTitle from '../HotelsTitle';
import RecommendCard from '../../RecommendCard';
import MiniCard from '../../Common/MiniCard';
import ScrollerDesktop from '../../ScrollerDesktop';

import type { ViewedHotelsData, City, HotelCards } from '../types';

import STYLES from './ViewedHotelsDesktopLayout.scss';

const cls = cssModules(STYLES);

type Props = {
  viewedHotelsData: ViewedHotelsData;
  layoutType?: Nullable<string>;
  destination?: string;
  onCardClicked: Function;
  viewedHotelsStack?: HotelCards;
  specificCities?: City[];
  hasSearchedCity?: boolean;
};

type State = {
  chevron: boolean;
};

const defaultProps = {
  layoutType: null,
  destination: null,
  specificCities: [],
  hasSearchedCity: false,
};

const VIEWED_HOTELS_CARD_WIDTH = 385;
const HALF_FILTERS_NUMBER = 6;

class ViewedHotelsDesktopLayout extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.state = {
      chevron: false,
    };
  }

  toggleChevron = () => {
    this.setState((prevState) => ({
      chevron: !prevState.chevron,
    }));
  };

  render() {
    const {
      destination,
      hasSearchedCity,
      layoutType,
      onCardClicked,
      specificCities = [],
      viewedHotelsData,
      viewedHotelsStack = [],
    } = this.props;
    const {
      hotelCards: fetchedHotels = [],
      isAvailableData,
      onSelectedChipChange,
      selectedChipId,
      sortedHotels,
    } = viewedHotelsData;

    const { chevron } = this.state;

    const originalHotels = [...viewedHotelsStack, ...fetchedHotels];
    const hotelCards =
      (originalHotels &&
        originalHotels.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
        )) ||
      [];

    const isSearchedCity =
      hasSearchedCity ||
      // @ts-expect-error TODO: cityId is not defined in hotel
      viewedHotelsStack.find((hotel) => hotel?.cityId === `${destination}`);
    const noHomePageFilters = layoutType && specificCities.length <= 1;
    const halfFilters =
      layoutType &&
      specificCities.length <= HALF_FILTERS_NUMBER &&
      specificCities.length > 1;

    let cards;
    if (layoutType) {
      cards =
        sortedHotels?.map((hotel, i) => (
          <RecommendCard
            key={hotel.id}
            position={i + 1}
            // TODO: This component is used for multiple features, but the dependent backend service data schema is not consistent
            // @ts-expect-error TS2739: Type 'HotelCard' is missing the following properties from type 'RecommendHotelCard': location, cug
            recommendHotel={hotel}
            generalType="similar"
            isGeneralType
            isViewedHotel
            onViewedHotelCardClick={onCardClicked}
          />
        )) ?? [];
    } else if (!layoutType && isSearchedCity) {
      cards = hotelCards
        // @ts-expect-error TS2551: Property 'cityId' does not exist on type 'HotelCard'
        .filter((hotel) => hotel.cityId === `${destination}`)
        .map((hotel, i) => (
          <MiniCard
            key={hotel.id}
            position={i + 1}
            hotel={hotel}
            onCardClicked={onCardClicked}
          />
        ));
    }

    return isAvailableData && cards && cards.length > 0 ? (
      <div
        className={cls(
          'ViewedHotelsDesktopLayout',
          !layoutType && 'ViewedHotelsDesktopLayout__searchPage',
        )}
      >
        <HotelsTitle
          isSearchedCity={!!isSearchedCity}
          layoutType={layoutType}
          toggleChevron={this.toggleChevron}
          chevron={chevron}
          className={cls(
            layoutType && 'ViewedHotelsDesktopLayout__title',
            !layoutType && !chevron && 'ViewedHotelsDesktopLayout__title--hide',
          )}
        />
        {layoutType && specificCities.length > 1 && (
          <HotelsFilter
            selectedChipId={selectedChipId}
            onSelectedChipChange={onSelectedChipChange}
            specificCities={specificCities}
            isMobile={false}
          />
        )}
        <ScrollerDesktop
          cards={cards}
          className={cls(
            'ViewedHotelsDesktopLayout__swiper',
            isSearchedCity && 'ViewedHotelsDesktopLayout__swiper--searchPage',
            chevron || layoutType
              ? 'ViewedHotelsDesktopLayout__swiper--chevronUp'
              : 'ViewedHotelsDesktopLayout__swiper--chevronDown',
            noHomePageFilters &&
              'ViewedHotelsDesktopLayout__swiper--noCityFilter',
            halfFilters && 'ViewedHotelsDesktopLayout__swiper--halfFilter',
          )}
          itemsPerGroup={3}
          spaceBetween={18}
          buttonPosition="outer"
          fixedWidth={layoutType ? 0 : VIEWED_HOTELS_CARD_WIDTH}
          needUpdate
          showImageBullets
          noMarginLeft={!!isSearchedCity}
          prevNavBtnClassName={cls(
            'ViewedHotelsDesktopLayout__swiperNavBtn',
            halfFilters &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--halfFilter',

            'ViewedHotelsDesktopLayout__swiperPrevNavBtn',
            isSearchedCity &&
              'ViewedHotelsDesktopLayout__swiperPrevNavBtn--searchPage',
            noHomePageFilters &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--noCityFilter',
            !chevron &&
              !layoutType &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--hidden',
          )}
          nextNavBtnClassName={cls(
            'ViewedHotelsDesktopLayout__swiperNavBtn',
            halfFilters &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--halfFilter',
            isSearchedCity &&
              'ViewedHotelsDesktopLayout__swiperNextNavBtn--searchPage',
            noHomePageFilters &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--noCityFilter',
            !chevron &&
              !layoutType &&
              'ViewedHotelsDesktopLayout__swiperNavBtn--hidden',
          )}
        />
      </div>
    ) : null;
  }
}

export default ViewedHotelsDesktopLayout;
