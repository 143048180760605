import React from 'react';

import { FormattedDistance } from '../../skyscanner-application/i18n';

import type { RelevantPOI } from '../../types/hotel-card';

type Props = {
  relevantPOI: RelevantPOI;
};

const DistancePOI = ({ relevantPOI: { distance, name } }: Props) => (
  <FormattedDistance
    label="Distance_label_fromPlace"
    distanceMeters={distance}
    data={{
      place: name,
    }}
  />
);

export default DistancePOI;
