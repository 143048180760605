import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../../skyscanner-application/i18n';
import { withElementEventTracker } from '../../../skyscanner-application/element-events';
import { ACTION_TYPE } from '../../../skyscanner-application/minievents/constants';
import ScrollerDesktop from '../../ScrollerDesktop';

import type { ElementEventTracker } from '../../../skyscanner-application/types';
import type { City } from '../types';

import STYLES from './HotelsFilter.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nService;
  elementEventTracker: ElementEventTracker;
  specificCities?: City[];
  isFullPage?: boolean;
  selectedChipId: string;
  onSelectedChipChange: Function;
  isMobile?: boolean;
};

const HotelsFilter = ({
  elementEventTracker,
  i18n,
  isFullPage,
  isMobile,
  onSelectedChipChange,
  selectedChipId,
  specificCities = [],
}: Props) => {
  const onClickFilterPill = (city: City) => {
    elementEventTracker.trackHotelsAction(
      ACTION_TYPE.VIEWED_HOTEL_CITY_FILTER_CLICK,
      {
        viewed_hotels_filter_clicked: {
          city_entity_id: city.id === 'all' ? 1 : parseInt(city.id, 10),
          is_in_full_page: isFullPage,
        },
      },
    );
    onSelectedChipChange(city);
  };

  const allCityObj = {
    id: 'all',
    text: i18n.translate('Viewed_hotels_allCities_lable'),
    count: specificCities.length,
  };

  const allCities = specificCities.length >= 1 && [
    allCityObj,
    ...specificCities,
  ];
  const chips =
    (allCities &&
      allCities.map((city) => (
        <BpkSelectableChip
          key={city.id}
          // @ts-expect-error kind and story for e2e tests
          kind={city}
          story={city}
          selected={selectedChipId === city.id}
          className={cls('HotelsFilter__chip')}
          accessibilityLabel="Press to toggle chip"
          onClick={() => onClickFilterPill(city)}
        >
          <BpkText textStyle={TEXT_STYLES.footnote}>{city.text}</BpkText>
        </BpkSelectableChip>
      ))) ||
    [];

  if (isMobile) {
    return (
      <div
        className={cls('HotelsFilter', isFullPage && 'HotelsFilter__fullPage')}
      >
        <BpkMobileScrollContainer className={cls('HotelsFilter__chipsScroll')}>
          {chips}
        </BpkMobileScrollContainer>
      </div>
    );
  }

  return (
    (chips && chips.length > 0 && (
      <ScrollerDesktop
        cards={chips}
        randomChildren
        enableMouseScroll
        prevNavBtnClassName={cls('HotelsFilter__swiperNavBtn')}
        nextNavBtnClassName={cls('HotelsFilter__swiperNavBtn')}
        buttonDisable={false}
      />
    )) ||
    null
  );
};

HotelsFilter.defaultProps = {
  isFullPage: false,
  specificCities: [],
  isMobile: true,
};

export default withElementEventTracker(withI18n(HotelsFilter));
