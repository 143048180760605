import React from 'react';

import BpkStarRating from '@skyscanner/backpack-web/bpk-component-star-rating';

import type { I18nService } from 'common/src/types/i18n';

import { withI18n } from '../../skyscanner-application/i18n';
import { getId, getAriaHidden } from '../../utils/a11y';

import type { Maybe } from '../types';

type Props = {
  i18n: I18nService;
  stars?: Maybe<number>;
  className?: Maybe<string>;
  index?: number;
  large?: boolean;
};

const starsLabel = (i18n: I18nService) => (stars: number) =>
  i18n.translatePlural('HotelInfo_number_stars', stars, 'number');

const HotelStars = ({ i18n, index, stars, ...rest }: Props) =>
  stars && stars > 0 ? (
    <BpkStarRating
      rating={stars}
      maxRating={stars}
      id={getId('hotel_star', index)}
      ratingLabel={starsLabel(i18n)}
      aria-hidden={getAriaHidden(index)}
      {...rest}
    />
  ) : null;

HotelStars.defaultProps = {
  stars: 0,
  className: null,
};

export default withI18n(HotelStars);
