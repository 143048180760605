import type { ReactNode } from 'react';
import React from 'react';

import {
  BpkButtonV2,
  BUTTON_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import RouteLink from '../../../../components/RouteLink';

import STYLES from './Section.scss';

const cls = cssModules(STYLES);

type Props = {
  children: ReactNode;
  actionLabel?: string;
  onActionClick?: () => void;
  dataTestId?: string;
  href?: string;
  actionLinkClassName?: string;
  showActionLink?: boolean;
  sectionClassName?: string;
};

const Section = ({
  actionLabel,
  actionLinkClassName,
  children,
  dataTestId,
  href,
  onActionClick = () => {},
  sectionClassName,
  showActionLink = false,
}: Props) => (
  <div className={cls('Section', sectionClassName)} data-test-id={dataTestId}>
    {children}
    {showActionLink && (
      <div
        className={cls(
          'Section__actionLink',
          'Section__visibleSmall',
          actionLinkClassName,
        )}
      >
        {href ? (
          <RouteLink
            href={href}
            onClick={onActionClick}
            render={(onClickLink, linkHref) => (
              <BpkButtonV2
                href={linkHref}
                type={BUTTON_TYPES.link}
                onClick={onClickLink}
              >
                {actionLabel}
              </BpkButtonV2>
            )}
          />
        ) : (
          <BpkButtonV2 type={BUTTON_TYPES.link} onClick={onActionClick}>
            {actionLabel}
          </BpkButtonV2>
        )}
      </div>
    )}
  </div>
);

export default Section;
